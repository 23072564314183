import React, { useEffect, useState } from 'react';
import {
    PoseLogV1Schema,
    PoseLogV2Schema,
    RenderKeypoints,
    poseLogIsV1,
    getV1PoseLogDimensions,
    convertV1PoseLog,
    poseLogIsV2,
    getV2PoseLogDimensions,
    convertV2PoseLog,
    getPhasesFromLog,
    PoseLogPhase,
    Assessment,
} from '@exerai/vision-engine-web';
import { Nullish } from '@exerai/vision-engine-web/dist/src/common/types';
import { Tabs, Tab } from '@mui/material';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { SessionReplay } from '@/components/Scan/Replay/SessionReplay';
import { resultIsError, Result } from '@/services/HttpService';
import { SessionResults } from './SessionResults';
import astGait from './astGait.json';
import { SessionReplayAndResultsTabsWrapper } from './styles';

interface SessionReplayProps {
    sessionUUID: string;
    client: 'gait' | 'scan';
}

export const SessionReplayAndResults = (props: SessionReplayProps) => {
    const { sessionUUID, client } = props;
    const { httpService } = useHttpContext();
    const [poseDimensions, setPoseDimensions] = useState<{ width: number; height: number }>({
        width: 900,
        height: 640,
    });
    const [poseLog, setPoseLog] = useState<Nullish<RenderKeypoints[]>>();
    const [phasesFromLog, setPhasesFromLog] = useState<PoseLogPhase[]>();
    const [tab, setTab] = useState(1);
    const [assessmentDefinition, setAssessmentDefinition] = useState<Assessment>();

    const handleChange = (_, e: number) => {
        setTab(e);
    };

    useEffect(() => {
        (async () => {
            const res = await httpService.adminGetSessionPoseLogSignedUrl(client, sessionUUID);
            if (resultIsError(res)) {
                alert(res.message);
                return;
            }
            const poseRes: Result<PoseLogV1Schema | PoseLogV2Schema> = await httpService.getExternalUrl(res);
            if (resultIsError(poseRes)) {
                alert(poseRes.message);
                return;
            }
            let convertedPoseLog: RenderKeypoints[];
            if (poseLogIsV1(poseRes)) {
                const dimensions = getV1PoseLogDimensions(poseRes);
                if (dimensions) {
                    setPoseDimensions(dimensions);
                }
                convertedPoseLog = convertV1PoseLog(poseRes);
                setPoseLog(convertedPoseLog);
            } else if (poseLogIsV2(poseRes)) {
                const dimensions = getV2PoseLogDimensions(poseRes);
                if (dimensions) {
                    setPoseDimensions(dimensions);
                }
                convertedPoseLog = convertV2PoseLog(poseRes);
                setPoseLog(convertedPoseLog);
                setPhasesFromLog(getPhasesFromLog(poseRes.meta));
            }
            setAssessmentDefinition(astGait as unknown as Assessment);
        })();
    }, [client, httpService, sessionUUID]);

    return poseLog && assessmentDefinition ? (
        <>
            <Tabs value={tab} onChange={handleChange}>
                <Tab label={'Replay'} value={1} />
                <Tab label={'Results'} value={2} />
            </Tabs>
            <SessionReplayAndResultsTabsWrapper>
                {tab === 1 ? (
                    <SessionReplay
                        poseDimensions={poseDimensions}
                        poseLog={poseLog}
                        phasesFromLog={phasesFromLog}
                        assessmentDefinition={assessmentDefinition}
                    />
                ) : (
                    <SessionResults
                        poseLog={poseLog}
                        phasesFromLog={phasesFromLog}
                        assessmentDefinition={assessmentDefinition}
                    />
                )}
            </SessionReplayAndResultsTabsWrapper>
        </>
    ) : (
        <>Loading...</>
    );
};
