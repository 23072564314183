import React, { useCallback, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Nullable } from '@/common/types';
import { ScanReportResultData } from '../types';
import { ReportCSVAssessment } from './types';

interface ScanAssessmentReportCSVActionProps {
    assessmentEntries: [string, Map<string, ScanReportResultData>][];
    assessmentMap: Map<string, ReportCSVAssessment>;
    title: string;
}

export const ScanAssessmentReportCSVAction = (props: ScanAssessmentReportCSVActionProps) => {
    const { assessmentMap, title } = props;
    const [generating, setGenerating] = useState(true);

    const hasData = (rows: any[]): boolean => {
        return rows.some((row) => row.length > 0);
    };

    const handleCsvRef = useCallback(
        async (node: Nullable<HTMLAnchorElement>) => {
            setGenerating(true);
            if (node && assessmentMap) {
                const rows: string[][] = [];
                [...assessmentMap].forEach((assessmentEntry, i) => {
                    const reportCSVAssessment = assessmentEntry[1];

                    rows.push([reportCSVAssessment.assessmentName]);
                    rows.push([...reportCSVAssessment.resultHeaders]);
                    reportCSVAssessment.sessionRows.forEach((sessionRow) => {
                        rows.push(sessionRow);
                    });
                    rows.push([]);
                });
                if (hasData(rows)) {
                    let csvContent = '';
                    csvContent += rows.map((e) => e.join(',')).join('\n');

                    const blob = new Blob(['\ufeff', csvContent]);
                    const url = URL.createObjectURL(blob);
                    node.download = `${title.replaceAll(':', '')}.csv`;
                    node.href = url;
                }
            }
            setGenerating(false);
        },
        [assessmentMap, title],
    );

    return (
        <a ref={handleCsvRef} onClick={() => null}>
            {assessmentMap.size > 0 && (
                <LoadingButton loading={generating} variant="primary" disabled={generating}>
                    Download CSV
                </LoadingButton>
            )}
        </a>
    );
};
