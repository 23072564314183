import React from 'react';
import { Typography, Box } from '@mui/material';
import { OrganizationAdmin } from '@/common/types';
import { AdminOutcomesList } from './Outcomes/OutcomesList';

interface AdminOrganizationScanOutcomesProps {
    organization: OrganizationAdmin;
}

export const AdminOrganizationScanOutcomes = (props: AdminOrganizationScanOutcomesProps) => {
    const { organization } = props;
    return organization ? (
        <>
            <Typography variant="h4">Scan Analysis</Typography>
            <Box>
                <AdminOutcomesList organization={organization} />
            </Box>
        </>
    ) : null;
};
