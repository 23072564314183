import { useState, useEffect } from 'react';
import React from 'react';
import {
    useExerVision,
    RenderKeypoints,
    poseLogIsV1,
    convertV1PoseLog,
    poseLogIsV2,
    convertV2PoseLog,
    AssessmentPhase,
    SessionResult,
    SessionResultDict,
    PoseLogV1Schema,
    PoseLogV2Schema,
    ResultUnit,
} from '@exerai/vision-engine-web';
import { Error } from '@mui/icons-material';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { Maybe, Nullish } from '@/common/types';
import { resultIsError, Result } from '@/services/HttpService';
import astGait from '../../../components/Scan/Replay/astGait.json';
import { SessionResultsPDFAction } from './SessionResultsPDFAction';
import { SessionParticipant } from './types';

interface SessionReplayProps {
    sessionUUID: string;
    client: 'gait' | 'scan';
    participant: SessionParticipant;
    organizationImageUrl: Maybe<string>;
    sessionSpeed: Maybe<number>;
    capturedDate: string;
}

export const SessionResults = (props: SessionReplayProps) => {
    const { sessionUUID, client, sessionSpeed } = props;
    const { httpService } = useHttpContext();
    const { session } = useExerVision();
    const { results, phase, assessment, setAssessment, processPoseLogAsSession } = session;
    const [poseLog, setPoseLog] = useState<Nullish<RenderKeypoints[]>>();

    useEffect(() => {
        (async () => {
            const res = await httpService.adminGetSessionPoseLogSignedUrl(client, sessionUUID);
            if (resultIsError(res)) {
                alert(res.message);
                return;
            }
            const poseRes: Result<PoseLogV1Schema | PoseLogV2Schema> = await httpService.getExternalUrl(res);
            if (resultIsError(poseRes)) {
                alert(poseRes.message);
                return;
            }
            let convertedPoseLog: RenderKeypoints[];
            if (poseLogIsV1(poseRes)) {
                convertedPoseLog = convertV1PoseLog(poseRes);
                setPoseLog(convertedPoseLog);
            } else if (poseLogIsV2(poseRes)) {
                convertedPoseLog = convertV2PoseLog(poseRes);
                setPoseLog(convertedPoseLog);
            }
            setAssessment(astGait as any);
        })();
    }, []);

    useEffect((): void => {
        if (poseLog) {
            processPoseLogAsSession(poseLog);
        }
    }, [poseLog, processPoseLogAsSession]);

    const fullResults: SessionResultDict = sessionSpeed
        ? {
            9999: { id: 9999, name: 'Speed', value: sessionSpeed, units: ResultUnit.METERS_SECOND, decimals: 2 },
            ...results,
        }
        : results;
    return (
        <>
            {phase === AssessmentPhase.COMPLETE ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'top',
                        width: '50%',
                        margin: '0 auto',
                        border: '1px solid #999999',
                        borderRadius: 4,
                        padding: 12,
                    }}
                >
                    <div style={{ flexGrow: 1 }}>
                        {Object.values(fullResults).map((result: SessionResult) => {
                            return (
                                <div key={result.id}>
                                    <strong>{result.name}:</strong> {result.value?.toFixed(result.decimals || 2)}{' '}
                                    {result.units}
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        {assessment ? (
                            <SessionResultsPDFAction results={fullResults} assessment={assessment} {...props} />
                        ) : (
                            <Error />
                        )}
                    </div>
                </div>
            ) : (
                <>Loading...</>
            )}
        </>
    );
};
