import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton, ThemeProvider } from '@mui/material';
import { Products } from '@/common/const';
import { DialogTitleContainer } from '@/components/Demo/Paths/styles';
import { Button } from '@/components/common/button/button';
import { quickDarkTheme } from '@/theme/exerTheme';

interface SessionReplayActionProps {
    product: Products;
    children: JSX.Element | JSX.Element[];
}

export const SessionReplayAction = (props: SessionReplayActionProps) => {
    const { product, children } = props;
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleCloseModal = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Button onClick={handleClick} size="small">
                Replay
            </Button>
            <ThemeProvider theme={quickDarkTheme}>
                {dialogOpen && (
                    <Dialog
                        open={true}
                        keepMounted
                        onClose={handleCloseModal}
                        fullWidth
                        maxWidth={'xl'}
                        PaperProps={{
                            style: {
                                backgroundColor: '#15161d',
                                backgroundImage: 'none',
                            },
                        }}
                    >
                        <DialogTitleContainer>
                            <DialogTitle>{product} Session Replay</DialogTitle>
                            <IconButton onClick={handleCloseModal}>
                                <Close />
                            </IconButton>
                        </DialogTitleContainer>
                        <DialogContent>{children}</DialogContent>
                    </Dialog>
                )}
            </ThemeProvider>
        </>
    );
};
