import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { ClientVersion } from '@/common/types';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { resultIsError } from '@/services/HttpService';
import { ClientFormModal } from './ClientFormModal';
import { ClientList } from './ClientList';

export const ClientVersionsContainer = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [clientList, setClientList] = useState<ClientVersion[]>([]);
    const { httpService } = useHttpContext();
    const { errorToast, successToast } = toasts;
    const [open, setOpen] = useState(false);
    const [editingIndex, setEditingIndex] = useState<number | null>(null);

    const getClientVersions = async () => {
        setIsLoading(true);
        const res = await httpService.adminGetClientVersions();
        if (resultIsError(res)) {
            errorToast('There was an issue getting all Scan Versions');
        } else {
            setClientList(res);
        }
        setIsLoading(false);
    };

    const updateClientVersion = async (clientVersion: ClientVersion, editingIndex: number) => {
        const res = await httpService.adminUpdateClientVersion(clientVersion);

        if (resultIsError(res)) {
            errorToast('There was an issue updating this Scan Version.');
        } else {
            const updatedClients = [...clientList];
            updatedClients[editingIndex] = res;
            setClientList(updatedClients);
            successToast('Successfully updated this Scan Version.');
        }
    };

    useEffect(() => {
        (async () => {
            await getClientVersions();
        })();
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setEditingIndex(null);
        setOpen(false);
    };

    const onSubmit = async (data: ClientVersion) => {
        setIsLoading(true);
        await updateClientVersion(data, editingIndex!);
        handleClose();
        await getClientVersions();
    };

    const handleEdit = (index: number) => {
        setEditingIndex(index);
        handleOpen();
    };

    return (
        <Container>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <ClientList clients={clientList} onEdit={handleEdit} />
                    {editingIndex && (
                        <ClientFormModal
                            open={open}
                            handleClose={handleClose}
                            onSubmit={onSubmit}
                            client={clientList[editingIndex]}
                        />
                    )}
                </>
            )}
        </Container>
    );
};
