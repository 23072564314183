import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Checkbox, Box } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { MAJOR_MINOR_PATCH_REG_EXP } from '@/common/const';
import { Button } from '@/components/common/button/button';

type Client = {
    versionId: string;
    updateRequired: boolean;
};

type ClientFormValues = {
    versionId: string;
    updateRequired: boolean;
};

interface ClientFormModalProps {
    open: boolean;
    handleClose: () => void;
    onSubmit: (data: Client) => void;
    client: Client;
}

export const ClientFormModal: React.FC<ClientFormModalProps> = ({ open, handleClose, onSubmit, client }) => {
    const schema = yup.object().shape({
        versionId: client
            ? yup.string()
            : yup
                  .string()
                  .required('Version ID is required')
                  .matches(MAJOR_MINOR_PATCH_REG_EXP, 'Version ID must match the pattern X.X.X'),
        updateRequired: yup.boolean().required(),
    });

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<ClientFormValues>({
        defaultValues: {
            versionId: '',
            updateRequired: false,
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (client) {
            reset(client);
        } else {
            reset({
                versionId: '',
                updateRequired: false,
            });
        }
    }, [client, reset]);

    const onFormSubmit = (data: ClientFormValues) => {
        onSubmit(data);
        reset({
            versionId: '',
            updateRequired: false,
        });
    };

    const onClose = () => {
        reset({
            versionId: '',
            updateRequired: false,
        });
        handleClose();
    };

    return (
        <Dialog open={open} onClose={onClose} style={{ padding: 16 }}>
            <DialogTitle>{`Edit Scan Version: ${client.versionId}`}</DialogTitle>

            <DialogContent style={{ minWidth: 400, paddingTop: 22 }}>
                <Box mb={2}>
                    <Controller
                        name="updateRequired"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Checkbox {...field} checked={field.value} />}
                                label="Update Required"
                            />
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit(onFormSubmit)}>{client ? 'Save Changes' : 'Add Client'}</Button>
            </DialogActions>
        </Dialog>
    );
};
