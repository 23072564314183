import React, { FC, useState } from 'react';
import { IconButton } from '@mui/material';
import { Maybe, Nullable } from '@/common/types';
import { PrintIcon } from '@/components/common/Icons/PrintIcon';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { getOutcomeFactorTableData } from '../OutcomeFactorTable/utils';
import { FactorCategory } from '../types/FactorCategory.enum';
import { OutcomeFactor, ParticipantDemographics, ScanSessionOutcomeData } from '../types/types';
import { getOutcomeBoxData } from '../utils';
import { SessionOutcomePrintActionWrapper } from './styles';

interface SessionOutcomePrintActionProps {
    scanSessionOutcomeData: ScanSessionOutcomeData;
    factorResultCategory: Maybe<FactorCategory>;
    sessionIdentifier: string;
    factors: Maybe<OutcomeFactor[]>;
    demographics: Maybe<ParticipantDemographics>;
    assessment: Maybe<{ id: string; name: string }>;
}

export const SessionOutcomePrintAction: FC<SessionOutcomePrintActionProps> = ({
    scanSessionOutcomeData,
    factorResultCategory,
    sessionIdentifier,
    factors,
    demographics,
    assessment,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<Nullable<string>>();
    const result = scanSessionOutcomeData.session!.data.results[0];

    const handleClick = async () => {
        try {
            setIsLoading(true);
            setErrorMessage(null);

            const outcomeBoxData = getOutcomeBoxData(result, factorResultCategory);
            const outcomeSliderData = { factors, factorResultCategory };
            let outcomeFactorTableData;
            if (demographics) {
                const outcomeFactorTableDataAlmost = getOutcomeFactorTableData(result, factors, demographics);
                outcomeFactorTableData = {
                    ...outcomeFactorTableDataAlmost,
                    sexMap: Object.fromEntries(outcomeFactorTableDataAlmost.sexMap),
                };
            }

            const response = await fetch(`${process.env.PDF_API_BASE_URL}render-pdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    pdfGroup: 'ASSESSMENT_OUTCOMES_PDF',
                    pdfName: sessionIdentifier,
                    pdfDocumentData: {
                        sessionData: {
                            participantUid: scanSessionOutcomeData.participantDetails.uid,
                            createdDate: scanSessionOutcomeData.createdDate,
                            assessmentName: scanSessionOutcomeData.assessmentName,
                            practitionerName: scanSessionOutcomeData.practitionerName,
                            demographics,
                            assessment,
                        },
                        outcomeBoxData,
                        outcomeSliderData,
                        outcomeFactorTableData,
                    },
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch PDF');
            }

            const blob = await response.blob();
            const pdfUrl = URL.createObjectURL(blob);
            window.open(pdfUrl, '_blank');
        } catch (err) {
            setErrorMessage(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <SessionOutcomePrintActionWrapper>
            {isLoading ? (
                <LoadingSpinner />
            ) : errorMessage ? (
                <div>{errorMessage}</div>
            ) : (
                <IconButton
                    aria-label="print assessment analysis"
                    onClick={() => handleClick()}
                    edge="end"
                    style={{ color: '#666666' }}
                >
                    <PrintIcon />
                </IconButton>
            )}
        </SessionOutcomePrintActionWrapper>
    );
};
