import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths, Products } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { Organization } from '@/common/types';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { Button } from '@/components/common/button/button';
import { resultIsError } from '@/services/HttpService';
import { OrganizationsTable } from './OrganizationsTable';

export const ScanOrganizationsTableContainer = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<Organization[]>();
    const { httpService } = useHttpContext();
    const navigate = useNavigate();

    const getOrganizations = async () => {
        setIsLoading(true);
        const res = await httpService.getOrganizations();
        if (resultIsError(res)) {
        } else {
            const filteredAndOrderedByName = res
                .filter((o) => o.products.includes(Products.SCAN.toUpperCase()))
                .sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            setRows(filteredAndOrderedByName);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await getOrganizations();
        })();
    }, []);

    const handleModulesClick = (organizationId: number) => {
        navigate(`${Paths.adminScanOrg}${organizationId}/modules`);
    };

    const handleOutcomesClick = (organizationId: number) => {
        navigate(`${Paths.adminScanOrg}${organizationId}/analysis`);
    };

    const columnStructure = [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ value }) => <strong>{value}</strong>,
        },
        {
            Header: 'Manage Modules',
            id: 'modules',
            accessor: 'id',
            Cell: ({ value }) => {
                return (
                    <Button onClick={() => handleModulesClick(value)} size="small" variant="secondary">
                        Modules
                    </Button>
                );
            },
        },
        {
            Header: 'Manage Analysis',
            id: 'outcomes',
            accessor: 'id',
            Cell: ({ value }) => {
                return (
                    <Button onClick={() => handleOutcomesClick(value)} size="small" variant="secondary">
                        Analysis
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                rows && <OrganizationsTable columnStructure={columnStructure} data={rows} />
            )}
        </div>
    );
};
