import React, { FormEvent, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { GAIT_TEST_UIDS } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { GaitSessionReport } from '@/common/types';
import { FormDateField } from '@/components/common/Form/Form';
import { ServerError } from '@/components/common/Form/styles';
import { resultIsError } from '@/services/HttpService';
import { GaitReportResults } from './GaitReportResults';

const shape = {
    startEnd: yup.date().nullable().default(undefined),
};
const schema = yup.object().shape(shape);

export const GaitReports = () => {
    const [serverError, setServerError] = useState<string | null>();
    const [sessions, setSessions] = useState<GaitSessionReport[]>();
    const [loading, setLoading] = useState(false);
    const [startEnd, setStartEnd] = useState<Date>(new Date());
    const { httpService } = useHttpContext();
    const { handleSubmit, control } = useForm({
        defaultValues: {
            startEnd: DateTime.now() as unknown as Date, //TODO need to wrangle some of the times still
        },
        resolver: yupResolver(schema),
    });

    const { errors } = useFormState({
        control,
    });

    const onSubmit = async (data: { startEnd: Date }) => {
        setServerError(null);
        setLoading(true);
        const start = DateTime.fromJSDate(new Date(data.startEnd)).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        });
        const end = DateTime.fromJSDate(new Date(data.startEnd)).set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
        });
        setStartEnd(data.startEnd);
        const result = await httpService.getGaitReport(start.toISO(), end.toISO());
        if (resultIsError(result)) {
            setServerError(result.message);
            return;
        }
        const nonTestSessions = result.filter((session): boolean => {
            if (GAIT_TEST_UIDS.includes(session.subject.gaitUid)) {
                return false;
            }
            return true;
        });
        setSessions(nonTestSessions);
        setLoading(false);
    };

    const validateAndSubmit = (e: FormEvent) => {
        e.preventDefault();
        handleSubmit(onSubmit)();
    };

    return (
        <>
            {!sessions ? (
                <form onSubmit={validateAndSubmit}>
                    <Grid container>
                        <Grid item>
                            <FormDateField
                                control={control}
                                errors={errors}
                                name="startEnd"
                                label={'Report Date'}
                                required={false}
                                noFuture={true}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="primary" type="submit">
                                Generate Report
                            </Button>
                        </Grid>
                    </Grid>
                    <ServerError>{serverError || '\u00a0'}</ServerError>
                </form>
            ) : loading ? (
                <LoadingButton loading={true} variant="primary" disabled={true}>
                    Fetching Data
                </LoadingButton>
            ) : sessions ? (
                <GaitReportResults sessions={sessions} setSessions={setSessions} startEnd={startEnd} />
            ) : (
                <ServerError>{serverError || '\u00a0'}</ServerError>
            )}
        </>
    );
};
