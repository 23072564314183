import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
    useExerVision,
    SessionResult,
    AssessmentPhase,
    PoseLogPhases,
    RenderKeypoints,
    Assessment,
} from '@exerai/vision-engine-web';
import { PauseCircle, PlayCircle } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { Maybe } from '@/common/types';
import { SessionReplayCanvasWrapper } from '@/components/common/Pose/styles';
import { ReplaySlider } from './ReplaySlider/ReplaySlider';
import {
    SessionReplayCurrentFrame,
    SessionReplayHeader,
    SessionReplayPhaseLabel,
    SessionReplayPlayControls,
} from './styles';

interface SessionReplayProps {
    poseDimensions: { width: number; height: number };
    poseLog: RenderKeypoints[];
    phasesFromLog: Maybe<PoseLogPhases>;
    assessmentDefinition: Assessment;
}

const REPLAY_SCALE = 0.66;

export const SessionReplay: FC<SessionReplayProps> = ({
    poseDimensions,
    poseLog,
    phasesFromLog,
    assessmentDefinition,
}) => {
    const { session, outputCanvasRef, draw, setKeypoints, isPlaying, handlePlay } = useExerVision();
    const { phase, setPhase, setAssessment, frameFeatureResults, results } = session;
    const [frame, setFrame] = useState(0);

    const isDelayedRef = useRef(false);

    const DEFAULT_FPS = 30;

    useEffect(() => {
        setAssessment(assessmentDefinition);
        handlePlay(true);
    }, [assessmentDefinition, handlePlay, setAssessment]);

    const advance = useCallback((): void => {
        if (
            isDelayedRef.current ||
            !isPlaying ||
            !poseLog ||
            poseLog.length === 0 ||
            phase === AssessmentPhase.COMPLETE
        ) {
            window.requestAnimationFrame(advance);
            return;
        }
        const activePhase = phasesFromLog?.reduce((high, curr) =>
            curr.value > high.value && frame >= curr.value ? curr : high,
        );
        if (activePhase) {
            setPhase(activePhase.phase);
        }

        const targetFrame = frame + 1;
        if (targetFrame >= poseLog.length) {
            setPhase(AssessmentPhase.COMPLETE);
            handlePlay(false);
        } else {
            setFrame((prevFrame) => {
                return prevFrame + 1;
            });
        }
        setKeypoints(poseLog[targetFrame]);
        if (DEFAULT_FPS) {
            isDelayedRef.current = true;
            setTimeout(() => {
                isDelayedRef.current = false;
            }, 1000 / DEFAULT_FPS);
        }
    }, [frame, handlePlay, isPlaying, phase, phasesFromLog, poseLog, setKeypoints, setPhase]);

    useEffect(() => {
        window.requestAnimationFrame(advance);
    }, [advance]);

    useEffect(() => {
        draw(REPLAY_SCALE);
    }, [draw]);

    const handleSlider = (_, frame) => {
        setFrame(frame);
        const activePhase = phasesFromLog?.reduce((high, curr) =>
            curr.value > high.value && frame >= curr.value ? curr : high,
        );
        if (activePhase) {
            setPhase(activePhase.phase);
        }
        if (poseLog) {
            setKeypoints(poseLog[frame]);
        }
        handlePlay(false);
    };

    return (
        <>
            <SessionReplayHeader>
                <SessionReplayPhaseLabel>
                    {phasesFromLog ? (
                        <>
                            Phase: <span>{phase}</span>
                        </>
                    ) : null}
                </SessionReplayPhaseLabel>
                <SessionReplayCurrentFrame>
                    {poseLog && frame !== undefined ? `Frame: ${frame} / ${poseLog.length}` : 'Loading'}
                </SessionReplayCurrentFrame>
            </SessionReplayHeader>

            <Grid container>
                <Grid item sm={10}>
                    <SessionReplayCanvasWrapper width={'100%'} height={'100%'}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <canvas
                                ref={outputCanvasRef}
                                width={poseDimensions.width / (1 / REPLAY_SCALE)}
                                height={poseDimensions.height / (1 / REPLAY_SCALE)}
                            />
                        </div>
                    </SessionReplayCanvasWrapper>
                </Grid>
                <Grid item sm={2}>
                    <div style={{ paddingLeft: 12, fontSize: 14 }}>
                        <h4 style={{ marginTop: 0 }}>Real-Time Results</h4>
                        {Object.values(results).map((result: SessionResult) => {
                            return (
                                <div key={result.id}>
                                    <strong>{result.name}:</strong> {result.value?.toFixed(2)} {result.units}
                                </div>
                            );
                        })}
                        <div>--</div>
                        {frameFeatureResults &&
                            Object.values(frameFeatureResults).map((result: SessionResult) => {
                                return (
                                    <div key={result.id}>
                                        <strong>{result.name}:</strong> {result.value?.toFixed(2)} {result.units}
                                    </div>
                                );
                            })}
                    </div>
                </Grid>
            </Grid>

            <SessionReplayPlayControls>
                <div>
                    <IconButton
                        aria-label="play/pause"
                        onClick={() => handlePlay()}
                        edge="end"
                        style={{ color: '#666666', fontSize: 36 }}
                    >
                        {isPlaying ? <PauseCircle fontSize="inherit" /> : <PlayCircle fontSize="inherit" />}
                    </IconButton>
                </div>
            </SessionReplayPlayControls>
            <ReplaySlider
                phases={phasesFromLog}
                frame={frame}
                frameCount={poseLog?.length}
                handleSlider={handleSlider}
            />
        </>
    );
};
