import React, { useState } from 'react';
import { FC } from 'react';
import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import { ScanSessionAdmin } from '@/common/types';
import { getAssessmentName } from '@/common/utils';
import { DialogTitleContainer } from '@/components/Demo/Paths/styles';
import { Button } from '@/components/common/button/button';
import { Center } from '@/components/common/styles';
import { useAssessmentsContext } from '@/components/context/hooks/AssessmentsContext';
import { ScanReportResultData } from '../types';
import { ScanAssessmentReportCSVAction } from './ScanAssessmentReportCSVAction';
import { ReportCSVAssessment } from './types';

interface ScanAssessmentReportCSVProps {
    sessions: ScanSessionAdmin[];
    assessmentEntries: [string, Map<string, ScanReportResultData>][];
    title: string;
}
export const ScanAssessmentReportCSV: FC<ScanAssessmentReportCSVProps> = (props) => {
    const { sessions, title } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const { assessmentsDict } = useAssessmentsContext();

    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleCloseModal = () => {
        setDialogOpen(false);
    };

    const assessmentMap = new Map<string, ReportCSVAssessment>();

    sessions.forEach((session) => {
        const assessmentId = session.session?.data?.assessmentId;
        if (!assessmentId) {
            return;
        }
        const resultMap = assessmentMap.get(assessmentId) || {
            assessmentId,
            assessmentName: getAssessmentName(assessmentId, assessmentsDict),
            resultHeaders: new Set<string>(),
            sessionRows: [],
        };
        resultMap.resultHeaders.add('Participant ID');
        const resultValues: string[] = [];
        session.session?.data.results.forEach((r) => {
            resultMap.resultHeaders.add(`${r.name} (${r.units})`);
            // TODO toFixed should be dynamic, but we need resultDefinitions first
            resultValues.push(r.value.toFixed(2).toString());
        });
        resultMap.sessionRows.push([session.participant?.uid || 'Unknown', ...resultValues]);
        assessmentMap.set(assessmentId, resultMap);
    });

    return (
        <>
            <Button onClick={handleClick}>Report CSV</Button>
            {dialogOpen && (
                <Dialog open={true} keepMounted onClose={handleCloseModal} fullWidth maxWidth={'lg'}>
                    <DialogTitleContainer>
                        <DialogTitle>{title}</DialogTitle>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </DialogTitleContainer>
                    <DialogContent>
                        <Center>
                            <ScanAssessmentReportCSVAction assessmentMap={assessmentMap} {...props} />
                        </Center>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};
