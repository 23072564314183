import React, { Dispatch, FC, SetStateAction } from 'react';
import { Close, Speed } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton, ThemeProvider } from '@mui/material';
import { Products } from '@/common/const';
import { DialogTitleContainer } from '@/components/Demo/Paths/styles';
import { Button } from '@/components/common/button/button';
import { quickDarkTheme } from '@/theme/exerTheme';

interface SessionOutcomesActionProps {
    product: Products;
    children: JSX.Element | JSX.Element[];
    dialogOpen: boolean;
    setDialogOpen: Dispatch<SetStateAction<boolean>>;
}

export const SessionOutcomesAction: FC<SessionOutcomesActionProps> = ({
    product,
    children,
    dialogOpen,
    setDialogOpen,
}) => {
    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleCloseModal = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Button onClick={handleClick} startIcon={<Speed />} variant="secondary" size="small">
                Analysis
            </Button>
            <ThemeProvider theme={quickDarkTheme}>
                {dialogOpen && (
                    <Dialog
                        open={true}
                        keepMounted
                        onClose={handleCloseModal}
                        fullWidth
                        maxWidth={'lg'}
                        PaperProps={{
                            style: {
                                backgroundColor: '#15161d',
                                backgroundImage: 'none',
                            },
                        }}
                    >
                        <DialogTitleContainer>
                            <DialogTitle>{product} Analysis</DialogTitle>
                            <IconButton onClick={handleCloseModal}>
                                <Close />
                            </IconButton>
                        </DialogTitleContainer>
                        <DialogContent>{children}</DialogContent>
                    </Dialog>
                )}
            </ThemeProvider>
        </>
    );
};
