import React, { useEffect, useState } from 'react';
import { DialogActions, TextField } from '@mui/material';
import { useAsyncDebounce } from 'react-table';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { AdminOrganization, Nullable } from '@/common/types';
import { AssessmentOutcome } from '@/components/Scan/Outcomes/types/types';
import { Button } from '@/components/common/button/button';
import { resultIsError } from '@/services/HttpService';
import { AssessmentAndOutcome } from './OutcomesList';
import { Dialog, DialogContent, DialogTitle, JSONCode } from './styles';

interface OutcomeModalProps {
    open: boolean;
    data: Nullable<AssessmentAndOutcome>;
    organization: AdminOrganization;
    onClose: () => void;
    refresh: () => void;
}

export const OutcomeModal = (props: OutcomeModalProps) => {
    const { open, onClose, refresh, data, organization } = props;
    const [jsonError, setJSONError] = useState<Nullable<string>>();
    const [initialJSON, setInitialJSON] = useState<Nullable<string>>();
    const [json, setJSON] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [prettyJSON, setPrettyJSON] = useState<React.ReactElement | null>();
    const { errorToast, successToast } = toasts;
    const { httpService } = useHttpContext();

    const onChange = useAsyncDebounce((value) => {
        try {
            setPrettyJSON(<pre>{JSON.stringify(JSON.parse(value), null, 2)}</pre>);
        } catch (e) {
            setJSONError('Invalid JSON.');
        }
    }, 1000);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setJSONError(null);
        setPrettyJSON(null);
        setJSON(e.target.value);
        onChange(e.target.value);
    };

    useEffect(() => {
        if (data?.outcome) {
            try {
                setInitialJSON(JSON.stringify(data.outcome, null, 2));
                setJSON(JSON.stringify(data.outcome, null, 2));
                setPrettyJSON(<JSONCode>{JSON.stringify(data.outcome, null, 2)}</JSONCode>);
            } catch (e) {
                errorToast('Could not parse organization scan outcome.');
                return;
            }
        }
    }, [data]);

    const close = () => {
        onClose();
        setIsEditing(false);
    };

    const handleSave = async () => {
        close();
        const parsed = JSON.parse(json) as AssessmentOutcome;
        if (data) {
            const res = await httpService.adminPutScanRiskFactorForAssessmentAndOrg(
                organization.id,
                data.assessment.id,
                parsed,
            );
            if (resultIsError(res)) {
                errorToast(res.message);
            } else {
                successToast('Successfully saved Scan outcome');
                refresh();
            }
        }
    };

    const isNew = data && data?.outcome.factors.length === 0;
    return (
        <Dialog open={open} onClose={close} maxWidth={false}>
            <DialogTitle>{isNew ? 'Create' : 'Edit'} Analysis</DialogTitle>
            <DialogContent style={{ fontSize: 'small' }}>
                {!isEditing ? (
                    <>
                        <JSONCode>{initialJSON}</JSONCode>
                    </>
                ) : (
                    <div style={{ margin: '16px' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ minWidth: '50%', marginRight: 24 }}>
                                <TextField
                                    size={'small'}
                                    value={json}
                                    onChange={handleChange}
                                    id="scanOutcomeJSON"
                                    label="Scan Analysis JSON"
                                    variant="outlined"
                                    error={!!jsonError}
                                    helperText={jsonError || '\u00a0'}
                                    multiline={true}
                                    fullWidth={true}
                                />
                            </div>
                            <div style={{ minWidth: '50%' }}>{jsonError || prettyJSON}</div>
                        </div>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isEditing}
                    onClick={() => {
                        setIsEditing(!isEditing);
                    }}
                >
                    Edit
                </Button>
                <Button onClick={close} variant="tertiary">
                    Cancel
                </Button>
                <Button type="submit" variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
