import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { DateTime } from 'luxon';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { ScanSessionAdmin } from '@/common/types';
import { usePaginationContext } from '@/components/context/hooks/PaginationContext';
import { resultIsError } from '@/services/HttpService';
import { ScanAssessmentReport } from './ScanAssessmentReport';
import { ScanParticipantReport } from './ScanParticipantReport';
import { ScanReportTabsWrapper } from './styles';

enum Feature {
    'ASSESSMENTS' = 'Assessments',
    'PARTICIPANTS' = 'Participants',
}
interface ScanReportResultsProps {
    sessions: ScanSessionAdmin[];
    setSessions: Dispatch<SetStateAction<ScanSessionAdmin[] | undefined>>;
    startEnd: Date;
}
export const ScanReportResults = (props: ScanReportResultsProps) => {
    const { sessions, setSessions, startEnd } = props;
    const { httpService } = useHttpContext();
    const { filters } = usePaginationContext();
    const [locationName, setLocationName] = useState<string>();
    const [feature, setFeature] = useState<Feature>(Feature.ASSESSMENTS);

    const handleChange = (_, f: Feature) => {
        setFeature(f);
    };

    const reset = () => {
        setSessions(undefined);
    };

    const handleReset = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        reset();
    };

    const eventTitle = `Exer Scan Report: ${DateTime.fromJSDate(new Date(startEnd)).toFormat('MM/dd/yyyy')}`;

    useEffect(() => {
        (async () => {
            const locationId = filters.find((filter) => {
                return filter.filterKey === 'locationId';
            })?.filterValue;
            if (!locationId) {
                return;
            }
            const res = await httpService.getLocations();
            if (!res || resultIsError(res)) {
                return;
            }
            res.forEach((location) => {
                if (location.id === +locationId) {
                    setLocationName(location.name);
                }
            });
        })();
    }, [filters, httpService]);

    return (
        <>
            <h2 style={{ position: 'relative' }}>
                {eventTitle}
                <span style={{ position: 'absolute', right: 0, fontSize: 14 }}>
                    <a href="#" onClick={handleReset}>
                        Generate new report
                    </a>
                </span>
            </h2>
            {sessions.length === 0 ? (
                <p>No sessions exist for that date.</p>
            ) : (
                <>
                    <Tabs value={feature} onChange={handleChange}>
                        <Tab label={Feature.ASSESSMENTS} value={Feature.ASSESSMENTS} />
                        <Tab label={Feature.PARTICIPANTS} value={Feature.PARTICIPANTS} />
                    </Tabs>
                    <ScanReportTabsWrapper>
                        {feature === Feature.ASSESSMENTS ? (
                            <>
                                <ScanAssessmentReport
                                    title={eventTitle}
                                    sessions={sessions}
                                    startEnd={startEnd}
                                    locationName={locationName}
                                />
                            </>
                        ) : null}
                        {feature === Feature.PARTICIPANTS ? (
                            <ScanParticipantReport
                                sessions={sessions}
                                startEnd={startEnd}
                                locationName={locationName}
                            />
                        ) : null}
                    </ScanReportTabsWrapper>
                </>
            )}
        </>
    );
};
