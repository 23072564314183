import React, { FC, useEffect } from 'react';
import {
    Assessment,
    AssessmentPhase,
    PoseLogPhases,
    RenderKeypoints,
    SessionResult,
    useExerVision,
} from '@exerai/vision-engine-web';
import { Maybe } from 'yup/lib/types';

interface SessionResultsProps {
    poseLog: RenderKeypoints[];
    phasesFromLog: Maybe<PoseLogPhases>;
    assessmentDefinition: Assessment;
}

export const SessionResults: FC<SessionResultsProps> = ({ poseLog, phasesFromLog, assessmentDefinition }) => {
    const { session } = useExerVision();
    const { assessment, results, setAssessment, processPoseLogAsSession } = session;

    useEffect(() => {
        setAssessment(assessmentDefinition);
    }, [assessmentDefinition, setAssessment]);

    useEffect(() => {
        if (assessment) {
            const assessingPhase = phasesFromLog?.find((phase) => {
                return phase.phase === AssessmentPhase.ASSESSING;
            });
            processPoseLogAsSession(poseLog, assessingPhase?.value);
        }
    }, [assessment, phasesFromLog, poseLog, processPoseLogAsSession]);

    const Results = () => {
        return (
            <>
                <h3>
                    These results have been dynamically generated by Vision Engine Web from the session pose log using
                    the latest available version. They may not match the reported results in app.
                </h3>
                {Object.values(results).map((result: SessionResult) => {
                    return (
                        <div key={result.id}>
                            <strong>{result.name}:</strong> {result.value?.toFixed(2)} {result.units}
                        </div>
                    );
                })}
            </>
        );
    };

    return <Results />;
};
