import React, { useCallback, useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { AdminOrganization, Assessment, Nullable } from '@/common/types';
import { OutcomeCategory } from '@/components/Scan/Outcomes/types/OutcomeCategory.enum';
import { AssessmentOutcome } from '@/components/Scan/Outcomes/types/types';
import { CreateIcon } from '@/components/common/Icons/CreateIcon';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { Button } from '@/components/common/button/button';
import { useAssessmentsContext } from '@/components/context/hooks/AssessmentsContext';
import { resultIsError } from '@/services/HttpService';
import { AssessmentCardContainer } from '../Assessments/styles';
import { OutcomeModal } from './OutcomeModal';

interface AdminOutcomesListProps {
    organization: AdminOrganization;
}

export interface AssessmentAndOutcome {
    outcome: AssessmentOutcome;
    assessment: Assessment;
}

const defaultOutcomes: AssessmentOutcome[] = [
    {
        type: OutcomeCategory.FALL,
        assessmentId: 'AST-0001',
        demographicsRequired: null,
        factors: [],
    },
    {
        type: OutcomeCategory.FALL,
        assessmentId: 'AST-0009',
        demographicsRequired: null,
        factors: [],
    },
    {
        type: OutcomeCategory.FALL,
        assessmentId: 'AST-0010',
        demographicsRequired: null,
        factors: [],
    },
    {
        type: OutcomeCategory.FALL,
        assessmentId: 'AST-0011',
        demographicsRequired: null,
        factors: [],
    },
    {
        type: OutcomeCategory.FALL,
        assessmentId: 'AST-0012',
        demographicsRequired: null,
        factors: [],
    },
    {
        type: OutcomeCategory.FALL,
        assessmentId: 'AST-0018',
        demographicsRequired: null,
        factors: [],
    },
    {
        type: OutcomeCategory.FALL,
        assessmentId: 'AST-0019',
        demographicsRequired: null,
        factors: [],
    },
    {
        type: OutcomeCategory.FALL,
        assessmentId: 'AST-0020',
        demographicsRequired: null,
        factors: [],
    },
    {
        type: OutcomeCategory.FALL,
        assessmentId: 'AST-0024',
        demographicsRequired: null,
        factors: [],
    },
];
export const AdminOutcomesList = (props: AdminOutcomesListProps) => {
    const [outcomes, setOutcomes] = useState<AssessmentOutcome[]>([]);
    const [assAndOut, setAssAndOut] = useState<Nullable<AssessmentAndOutcome>>(null);
    const [isOpen, setIsOpen] = useState(false);
    const { organization } = props;
    const { errorToast } = toasts;
    const { httpService } = useHttpContext();
    const { assessmentsDict } = useAssessmentsContext();

    const handleEditClick = (ao: AssessmentAndOutcome) => {
        setAssAndOut(ao);
        setIsOpen(true);
    };
    const preloadDefaults = () => {
        setOutcomes(defaultOutcomes);
    };

    const getOutcomes = useCallback(async () => {
        const res = await httpService.adminGetScanRiskFactorOutcomesByOrg(organization.id);
        if (!res || resultIsError(res)) {
            errorToast(`Could not load analysis data. ${res?.message || ''}`);
            return;
        }
        if (res.length === 0) {
            setOutcomes([]);
        } else {
            const mergedOutcomes = defaultOutcomes.map((d) => {
                const outcome = res.find((o) => o.assessmentId === d.assessmentId);

                return outcome ? outcome : d;
            });
            setOutcomes(mergedOutcomes);
        }
    }, [httpService, organization.id]);

    useEffect(() => {
        (async () => {
            await getOutcomes();
        })();
    }, [getOutcomes]);
    const assessmentOutcomes =
        assessmentsDict &&
        outcomes.map((o) => ({
            outcome: o,
            assessment: assessmentsDict[o.assessmentId],
        }));
    return (
        <div style={{ marginTop: 16 }}>
            {assessmentOutcomes?.map((ao) => (
                <AssessmentCardContainer key={ao.assessment.id} style={{ width: '500px' }}>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {ao.assessment.thumbnailUrl && (
                            <Box
                                style={{
                                    marginRight: 8,
                                }}
                            >
                                <img src={ao.assessment.thumbnailUrl} width={50} height={50} />
                            </Box>
                        )}
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography variant="h5">{ao.assessment.name}</Typography>
                            <Typography variant="caption">({ao.assessment.subjectOrientation})</Typography>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 16,
                            }}
                        >
                            <IconButton onClick={() => handleEditClick(ao)} size="small">
                                {ao.outcome.factors.length > 0 ? <EditIcon /> : <CreateIcon />}
                            </IconButton>
                        </Box>
                    </Box>
                </AssessmentCardContainer>
            ))}
            <Box>
                <OutcomeModal
                    open={isOpen}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    refresh={() => getOutcomes()}
                    data={assAndOut}
                    organization={organization}
                />
            </Box>
            {assessmentOutcomes?.length === 0 && (
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        marginLeft: 16,
                        flexDirection: 'column',
                        height: 200,
                    }}
                >
                    No Scan Analysis is configured for this organization. Would you like to preload the default
                    configuration?
                    <Button onClick={preloadDefaults}>Preload Defaults</Button>
                </Box>
            )}
        </div>
    );
};
