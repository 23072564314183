import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { PAGE_SIZE } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useMuiUtils } from '@/common/hooks/MuiUtils';
import { toasts } from '@/common/toasts';
import { Maybe, ScanSessionRow, ScanSessionsPagedRequestParams } from '@/common/types';
import { ScanSessionJSON } from '@/common/types';
import { getAssessmentName, getUrlSafeAssessmentName } from '@/common/utils';
import { EmptyDataWrapper } from '@/components/common/EmptyData/styles';
import { CalendarIcon } from '@/components/common/Icons/CalendarIcon';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { LocationFilter } from '@/components/common/Table/Filters/LocationFilter';
import { TableActionsWrapper } from '@/components/common/Table/styles';
import { useAssessmentsContext } from '@/components/context/hooks/AssessmentsContext';
import { usePaginationContext } from '@/components/context/hooks/PaginationContext';
import { resultIsError } from '@/services/HttpService';
import { AssessmentOutcome } from '../Outcomes/types/types';
import { outcomeJSONToMap } from '../Outcomes/utils';
import { ScanSessionsTable } from './ScanSessionsTable';
import { ScanSessionsTableResults } from './ScanSessionsTableResults';
import { SessionOutcomesActionContainer } from './SessionOutcomesActionContainer';
import { ScanSessionsTableWrapper, ListControlsContainer } from './styles';

export const ScanSessionsTableContainer: FC = () => {
    const { httpService } = useHttpContext();
    const { assessmentsDict } = useAssessmentsContext();
    const { errorToast } = toasts;
    const { palette } = useMuiUtils();
    const { user } = useExerUser();
    const [rows, setRows] = useState<ScanSessionRow[]>([]);
    const { page, setPaginationMeta, filters } = usePaginationContext();
    const [hasLocationFilter, setHasLocationFilter] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState(false);
    const [outcomes, setOutcomes] = useState<Maybe<Map<string, AssessmentOutcome>>>();

    const getRequestParams = useCallback((): Maybe<ScanSessionsPagedRequestParams> => {
        const params = {} as ScanSessionsPagedRequestParams;
        params.page = page;
        params.limit = PAGE_SIZE;

        filters.forEach((filter) => {
            params[filter.filterKey] = filter.filterValue;
        });
        if (!params['locationId']) {
            setHasLocationFilter(false);
            return undefined;
        } else {
            setHasLocationFilter(true);
        }
        return params;
    }, [page, filters]);

    const getSessions = useCallback(async () => {
        setIsLoading(true);
        const params = getRequestParams();
        if (!params) {
            setIsLoading(false);
            return;
        }
        const res = await httpService.getScanSessions(params);

        if (!res || resultIsError(res)) {
            errorToast(`Could not load scan sessions. ${res?.message || ''}`);
        } else {
            const resultRows = res.items.map(
                ({ id, createdDate, sessionUUID, session, practitioner, artifacts, participant }) => {
                    const assessmentName = getAssessmentName(session?.data.assessmentId, assessmentsDict);
                    return {
                        id,
                        createdDate,
                        session,
                        practitionerName: practitioner ? `${practitioner.firstName} ${practitioner.lastName}` : '',
                        assessmentName,
                        action: {
                            sessionUUID,
                            artifacts,
                            sessionIdentifier: `${DateTime.fromISO(createdDate).toFormat(
                                'MM-dd-yyyy',
                                // eslint-disable-next-line prettier/prettier
                            )}_${getUrlSafeAssessmentName(session?.data.assessmentId, assessmentsDict)}${
                                (participant && `_${participant.uid}`) || ''
                                // eslint-disable-next-line prettier/prettier
                            }`,
                        },
                        participantDetails: {
                            uid: (participant && participant.uid) || '',
                            archivedDate: (participant && participant.isArchived && participant.archivedDate) || null,
                        },
                    };
                },
            );
            setRows(resultRows);
            setPaginationMeta(res.metadata);
        }
        setIsLoading(false);
    }, [httpService, setPaginationMeta, getRequestParams]);

    const getOutcomes = useCallback(async () => {
        const res = await httpService.getScanRiskFactorOutcomes();
        if (!res || resultIsError(res)) {
            errorToast(`Could not load analysis data. ${res?.message || ''}`);
            return;
        }
        const o = outcomeJSONToMap(res);
        setOutcomes(o);
    }, [httpService]);

    useEffect(() => {
        (async () => {
            await getOutcomes();
        })();
    }, [getOutcomes, user]);

    useEffect(() => {
        (async () => {
            await getSessions();
        })();
    }, [getSessions]);

    const columnStructure = [
        {
            Header: 'Assessment',
            accessor: 'assessmentName',
            width: '15%',
            Cell: ({ value }) => <>{value}</>,
        },
        {
            Header: 'Created',
            accessor: 'createdDate',
            width: '15%',
            Cell: ({ value }) => (
                <>
                    {value ? (
                        <>
                            {DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED)}{' '}
                            {DateTime.fromISO(value).toLocaleString(DateTime.TIME_WITH_SECONDS)}
                        </>
                    ) : (
                        ''
                    )}
                </>
            ),
        },
        {
            Header: 'Results',
            accessor: 'session',
            width: '20%',
            Cell: ({ value }) => {
                const session = value as ScanSessionJSON;
                return <ScanSessionsTableResults session={session} />;
            },
        },
        {
            Header: 'UID',
            accessor: 'participantDetails',
            width: '10%',
            Cell: ({ value }) => (
                <>
                    <Typography>{value.uid}</Typography>
                    {value.archivedDate && (
                        <Typography variant="subtitle2">
                            <Tooltip
                                title={`Archived on: ${DateTime.fromISO(value.archivedDate).toLocaleString(
                                    DateTime.DATETIME_SHORT_WITH_SECONDS,
                                )}`}
                            >
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    Archived
                                    <Box style={{ color: palette.info[500], display: 'flex', marginLeft: 4 }}>
                                        <CalendarIcon />
                                    </Box>
                                </Box>
                            </Tooltip>
                        </Typography>
                    )}
                </>
            ),
        },
        {
            Header: 'Action',
            accessor: 'action',
            width: '30%',
            Cell: ({ row, value: { sessionIdentifier } }) => {
                const scanSessionRow: ScanSessionRow = row.original;
                return (
                    <TableActionsWrapper>
                        {outcomes && (
                            <SessionOutcomesActionContainer
                                scanSessionOutcomeData={scanSessionRow}
                                sessionIdentifier={sessionIdentifier}
                                outcomes={outcomes}
                            />
                        )}
                    </TableActionsWrapper>
                );
            },
        },
    ];

    return (
        <>
            <PageTitle title="Scan Sessions" />
            <ScanSessionsTableWrapper>
                <ListControlsContainer>
                    <LocationFilter />
                </ListControlsContainer>
                {!hasLocationFilter ? (
                    <EmptyDataWrapper>
                        <Typography variant="h4">Select Location</Typography>
                        <Typography variant="subtitle1" fontWeight={500}>
                            Please select a location to see sessions.
                        </Typography>
                    </EmptyDataWrapper>
                ) : (
                    <ScanSessionsTable columnStructure={columnStructure} data={rows} isLoading={isLoading} />
                )}
            </ScanSessionsTableWrapper>
        </>
    );
};
